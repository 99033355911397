import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import TablePagination from '@material-ui/core/TablePagination'
import Checkbox from '@material-ui/core/Checkbox'
import Helper from '../../utils/Helper'
import { withRouter } from 'react-router-dom'

const components = {
  Pagination: (props: any) => (
    <TablePagination {...props} style={{ direction: 'ltr' }} />
  ),
}

const Series = (props: any) => {
  const { history } = props

  const [series, setSeries] = useState([])

  useEffect(() => {
    fetchSeries()
  }, [])

  const fetchSeries = async () => {
    const result = await Helper.ApiRequest('/series')
    setSeries(result)
  }

  const pageTitle = 'Series'
  const columns = [
    { title: 'Level', field: 'level' },
    { title: 'Title', field: 'title' },
    { title: 'Order', field: 'order' },
    { title: 'Episodes', field: 'episodesCount' },
    {
      title: 'Published',
      field: 'state',
      type: 'boolean',
      render: (rowData) => (
        <Checkbox checked={rowData.state === 'published'} disabled />
      ),
      customFilterAndSearch: (term, rowData) => {
        if (term === 'checked') {
          return rowData.state === 'published'
        } else if (term === 'unchecked') {
          return rowData.state !== 'published'
        }
      },
    },
  ]
  const options = {
    columnsButton: true,
    exportButton: true,
    filtering: true,
    emptyRowsWhenPaging: false,
    pageSize: 10,
    actionsColumnIndex: -1,
  }

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Edit series',
      onClick: (e, rowData) => {
        handleEdit(e, rowData.slug, rowData)
      },
    },
  ]

  const handleEdit = (e, slug, series) => {
    if (!slug) {
      return
    }
    history.push({ pathname: `/series/${slug}`, state: series })
  }

  return (
    <MaterialTable
      // @ts-expect-error legacy
      columns={columns}
      data={series}
      title={pageTitle}
      options={options}
      components={components}
      actions={actions}
    />
  )
}

export default withRouter(Series)
