import React, { useEffect, useState } from 'react'
import MaterialTable, { Action, Column, Options } from 'material-table'
import TablePagination from '@material-ui/core/TablePagination'
import Checkbox from '@material-ui/core/Checkbox'
import Helper from '../../utils/Helper'
import { withRouter } from 'react-router-dom'
import { ICompetitionNew } from './type'

const components = {
  Pagination: (props: any) => (
    <TablePagination {...props} style={{ direction: 'ltr' }} />
  ),
}

const Competition = (props) => {
  const { history } = props

  const [competitions, setCompetitions] = useState<ICompetitionNew[]>([])

  useEffect(() => {
    const fetchCompetitions = async () => {
      const result = await Helper.ApiRequest('/competitionsNew')
      setCompetitions(result)
    }

    fetchCompetitions()
  }, [])

  const pageTitle = 'Competition'
  const columns: Column[] = [
    {
      title: 'Level',
      field: 'selectedPath',
      render: (rowData) =>
        rowData?.selectedPaths.map((path) => path.level).join(' , '),
      customFilterAndSearch: (term, rowData) => {
        return rowData.selectedPaths.some(
          (path) => path.level.toString() === term,
        )
      },
    },
    { title: 'Title', field: 'title' },
    { title: 'Slug', field: 'slug' },
    { title: 'Start Date', field: 'startDate' },
    { title: 'End Date', field: 'endDate' },
    { title: 'No of Challenges', field: 'numberOfChallenges' },
    {
      title: 'Published',
      field: 'state',
      type: 'boolean',
      render: (rowData) => (
        <Checkbox checked={rowData.state === 'published'} disabled />
      ),
      customFilterAndSearch: (term, rowData) => {
        // Map 'checked' to 'published' and 'unchecked' to anything else
        if (term === 'checked') {
          return rowData.state === 'published'
        } else if (term === 'unchecked') {
          return rowData.state !== 'published'
        }
      },
    },
  ]

  const options: Options = {
    columnsButton: true,
    exportButton: true,
    filtering: true,
    emptyRowsWhenPaging: false,
    pageSize: 10,
    actionsColumnIndex: -1,
    search: false,
  }

  const actions: Action[] = [
    {
      icon: 'edit',
      tooltip: 'Edit competition',
      onClick: (e, rowData) => {
        handleEdit(e, rowData._id, rowData)
      },
    },
  ]

  const handleEdit = (e, _id, competition) => {
    if (!_id) {
      return
    }
    history.push(`/competitions/${_id}`)
  }

  return (
    <MaterialTable
      columns={columns}
      data={competitions}
      title={pageTitle}
      options={options}
      components={components}
      actions={actions}
    />
  )
}

export default withRouter(Competition)
