import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import OnboardingForm from '../../components/onboarding/OnboardingForm'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'
import { OnboardingAddStyle as styles } from './OnboardingStyles'
function OnboardingAdd(props: any) {
  const [onboarding, setOnboarding]: any = useState({
    title: '',
    deepLink: '',
    orderNo: 0,
    imageUrl: '',
    status: true,
  })
  const { history, enqueueSnackbar, classes } = props

  const saveOnboarding = () => {
    const [promise, onSuccess] = getSaveRequest(onboarding)
    doAndSnack(promise, enqueueSnackbar, {
      success: 'Onboarding page created successfully',
      fail: 'Failed to create onboarding page',
      onSuccess,
    })
  }

  const onSuccess = () => {
    history.push('/onboardings')
  }

  const getSaveRequest = (onboarding) => {
    const path = '/onboarding'
    const method = 'POST'
    const body = JSON.stringify(onboarding)
    const promise = Helper.ApiRequest(path, { method, body })
    return [promise, onSuccess]
  }

  const handleChange = (data) => {
    setOnboarding((prevData) => ({ ...prevData, ...data }))
  }

  return (
    <React.Fragment>
      <FormTitle />
      <Paper className={classes.paper}>
        <OnboardingForm
          // @ts-expect-error legacy
          onChange={handleChange}
          onSave={saveOnboarding}
          onboarding={onboarding}
        />
      </Paper>
    </React.Fragment>
  )
}

OnboardingAdd.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(withRouter(withStyles(styles)(OnboardingAdd)))

function FormTitle() {
  return (
    <Typography component="a" variant="h4" align="center">
      Create Onboarding Screen
    </Typography>
  )
}
