const commonStyle = (theme) => ({
  root: { flexGrow: 1, marginTop: theme.spacing.unit * 3 },
  paper: {
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
  },
})

export const CategoryAddStyle: any = (theme) => ({
  ...commonStyle(theme),
})

export const CategoriesStyle: any = (theme) => ({
  ...commonStyle(theme),
  title: {
    fontWeight: 'bold',
  },
  tableHeader: {
    fontWeight: 'bold',
    align: 'center',
  },
  tableCell: {
    borderBottom: 'none',
  },
})

export const CategoryDetailStyle: any = (theme) => ({
  ...commonStyle(theme),
})
