import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import EpisodeForm from '../../components/EpisodeForm'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'
import { EpisodeAddStyles as styles } from './EpisodesStyle'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const EpisodeAdd = (props: any) => {
  const [episode, setEpisode]: any = useState({
    title: '',
    slug: '',
    order: '',
    // path: '',
    description: '',
    state: 'draft',
    eduTargets: [],
    thumbnail: '',
    image: '',
    isFree: false,
    isAnon: false,
    forVisitor: false,
    handbook: '',
  })
  const [visitorEpisode, setVisitorEpisode] = useState([])
  const { classes, enqueueSnackbar } = props
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const visitor = searchParams.get('visitor')

  useEffect(() => {
    if (visitor) {
      setEpisode((prevEp) => ({ ...prevEp, forVisitor: visitor }))
    }
  }, [visitor])

  const postEpisode = async () => {
    return await Helper.ApiRequest('/episodes/', {
      method: 'POST',
      body: JSON.stringify(episode),
    })
  }

  useEffect(() => {
    if (episode.isAnon) {
      setEpisode((prevEp) => ({ ...prevEp, isFree: true }))
    }
  }, [episode.isAnon])

  useEffect(() => {
    fetchEpisodes()
  }, [])

  const fetchEpisodes = async () => {
    const data = await Helper.ApiRequest('/episodes/visitor/visitor-episode')
    setVisitorEpisode(data)
  }

  const saveEpisode = async () => {
    const { slug } = episode

    if (visitor && visitorEpisode.length > 0) {
      enqueueSnackbar('Only 1 visitor episode can be added', {
        variant: 'error',
      })
      return
    }

    if (!episode?.path && !visitor) {
      enqueueSnackbar('Path is required', { variant: 'error' })
      return
    }

    if (!episode.title) {
      enqueueSnackbar('Title is required', { variant: 'error' })
      return
    }

    if (!episode.slug) {
      enqueueSnackbar('Slug is required', { variant: 'error' })
      return
    }

    if (!episode.isAnon && episode.order <= 0) {
      enqueueSnackbar('Order must be greater than 0', { variant: 'error' })
      return
    }

    if (episode.series) {
      const { order, series } = episode
      const seriesData = await Helper.ApiRequest(`/series/${series._id}`)
      const filteredEpisodeBefore = seriesData.episodes.filter(
        (e) => e.order <= order && e._id !== episode._id && !e.isAnon,
      )
      const filteredEpisodeAfter = seriesData.episodes.filter(
        (e) =>
          e.order > order && e._id !== episode._id && e.isFree && !e.isAnon,
      )
      if (filteredEpisodeBefore.length > 0 && episode.isFree) {
        if (!filteredEpisodeBefore.some((e) => e.isFree)) {
          enqueueSnackbar(
            'Free episode need to be at the top order of series.',
            {
              variant: 'error',
            },
          )
          return
        }
        if (filteredEpisodeBefore.some((e) => !e.isFree)) {
          enqueueSnackbar('Cannot have episode free between paid episode.', {
            variant: 'error',
          })
          return
        }
      }
      if (!episode.isFree && filteredEpisodeAfter.length > 0) {
        enqueueSnackbar('Cannot have episode free between paid episode.', {
          variant: 'error',
        })
        return
      }
    }
    doAndSnack(postEpisode(), enqueueSnackbar, {
      success: 'Episode succesfully saved',
      fail: 'Failed to save episode',
      onSuccess: () => history.push(`/episodes/${slug}/details`),
    })
  }

  const handleChange = (episode) => {
    setEpisode({ ...episode })
  }

  return (
    <>
      <Typography component="h1" variant="h4" align="center">
        Add a new {visitor && 'visitor'} episode
      </Typography>
      <Paper className={classes.paper}>
        <EpisodeForm
          // @ts-expect-error legacy
          episode={episode}
          onChange={handleChange}
          onSave={saveEpisode}
          isVisitor={visitor ?? false}
        />
      </Paper>
    </>
  )
}

EpisodeAdd.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(withRouter(withStyles(styles)(EpisodeAdd)))
