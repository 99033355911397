const styles: any = (theme) => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    width: '50%',
    margin: 2,
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
    width: '23%',
  },
  input: {
    display: 'none',
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
  container: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  upload: {
    margin: theme.spacing.unit,
    width: '70%',
  },
  imgDisplay: {
    width: 'auto',
    height: '200px',
    objectFit: 'cover',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  title: {
    fontWeight: 'bold',
  },
})

export default styles
