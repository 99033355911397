import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import './PDFViewer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFViewer = ({ url }: any) => {
  const [numPages, setNumPages] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [loadError, setLoadError] = useState(false)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setIsLoading(false)
    setLoadError(false)
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1)
  }

  const handleLoadError = () => {
    setIsLoading(false)
    setLoadError(true)
  }

  return (
    <div className="pdf-container">
      {url && numPages !== 1 && !loadError && (
        <div className="pdf-controls">
          {currentPage !== numPages && (
            <button
              type="button"
              className="control-button"
              onClick={handleNextPage}
              disabled={currentPage >= numPages}
            >
              <div>Next</div>
            </button>
          )}
          {currentPage !== 1 && (
            <button
              type="button"
              className="control-button"
              onClick={handlePreviousPage}
              disabled={currentPage <= 1}
            >
              <div>Prev</div>
            </button>
          )}
        </div>
      )}

      <div className="pdf-content">
        {url && isLoading && (
          <div className="pdf-loading ltr" style={{ direction: 'ltr' }}>
            Loading...
          </div>
        )}
        {loadError && (
          <div className="pdf-error" style={{ direction: 'ltr' }}>
            Failed to load PDF.
          </div>
        )}
        {!loadError && (
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={handleLoadError}
            renderMode="canvas"
          >
            <Page
              pageNumber={currentPage}
              scale={1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
        )}
      </div>
    </div>
  )
}

export default PDFViewer
