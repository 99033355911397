import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { memo } from 'react'

const CategoryField = memo(
  ({ classes, value, handleChange, type = 'game' }: any) => {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="cat-simple">Category</InputLabel>
        <Select
          value={value}
          onChange={handleChange('category')}
          inputProps={{
            id: 'cat-simple',
          }}
        >
          {type === 'game' ? (
            <MenuItem value="game">game</MenuItem>
          ) : (
            <MenuItem value="book">book</MenuItem>
          )}
        </Select>
      </FormControl>
    )
  },
)

export default CategoryField
