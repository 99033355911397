import React, { useEffect, useState } from 'react'
import { IChallenge, IChallengeAdd, ICompetitionNew } from '../type'
import Helper from '../../../utils/Helper'
import { doAndSnack, snackProgressBar } from '../../../utils/snackbar'
import {
  Typography,
  Paper,
  withStyles,
  CircularProgress,
} from '@material-ui/core'
import ChallengeForm from '../../../components/ChallengeForm'
import { withRouter, useParams, Link } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import LinkIcon from '@material-ui/icons/Link'
import { OUT_OF_BOUND_DATE_MESSAGE } from '../../../constants/ErrorMessages'

const styles: any = (theme) => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
  },
})

function basicValidateCompetition(challenge: IChallengeAdd, enqueueSnackbar) {
  const validations = [
    { check: () => !challenge?.title, message: 'Title is required' },
    {
      check: () => !challenge?.url,
      message: 'Url is required',
    },
    { check: () => !challenge?.startDate, message: 'Start Date is required' },
    { check: () => !challenge?.endDate, message: 'End Date is required' },
    { check: () => !challenge?.slug, message: 'Slug is required' },
  ]

  for (const validation of validations) {
    if (validation.check()) {
      snackProgressBar(
        enqueueSnackbar,
        { success: '', fail: validation.message },
        false,
      )
      return false
    }
  }

  return true
}

const ChallengesAdd = (props) => {
  const { classes, enqueueSnackbar, history, location } = props

  const [competition, setCompetition] = useState<ICompetitionNew>()
  const [isLoadingCompetition, setIsLoadingCompetition] = useState(true)

  const [challenge, setChallenge] = useState<IChallengeAdd>({
    title: '',
    slug: '',
    startDate: '',
    endDate: '',
    state: 'draft',
    url: '',
    competition,
  })

  const { id } = useParams()

  useEffect(() => {
    const fetchCompetition = async () => {
      try {
        setIsLoadingCompetition(true)
        const result = await Helper.ApiRequest(`/competitionsNew/${id}`)

        setCompetition(result)
        setChallenge((data) => ({ ...data, competition: result }))
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoadingCompetition(false)
      }
    }

    fetchCompetition()
  }, [id])

  const handleChange = (challenge) => {
    setChallenge({
      ...challenge,
    })
  }

  const saveChallenge = () => {
    const isChallengeValidate = basicValidateCompetition(
      challenge,
      enqueueSnackbar,
    )

    if (!isChallengeValidate) return

    const challStartDate = new Date(challenge?.startDate)
    const challEndDate = new Date(challenge?.endDate)
    const compStartDate = new Date(competition?.startDate)
    const compEndDate = new Date(competition?.endDate)

    if (challStartDate > compEndDate || challEndDate < compStartDate) {
      snackProgressBar(
        enqueueSnackbar,
        { success: '', fail: OUT_OF_BOUND_DATE_MESSAGE },
        false,
      )
      return
    }

    challEndDate.setUTCHours(23, 59, 59, 999)
    challenge.endDate = challEndDate.toISOString()

    const promise = Helper.ApiRequest(`/challenges`, {
      method: 'POST',
      body: JSON.stringify(challenge),
    })

    const onSuccess = () => {
      history.push('/competitions')
    }

    doAndSnack(promise, enqueueSnackbar, {
      success: 'Challenge succesfully added',
      fail: 'Failed to add challenge',
      onSuccess,
    })
  }

  const reservedDates = competition?.challenges
    .filter((chall) => chall.state === 'published')
    .map((challenge: IChallenge) => {
      // if startDate and endDate is the same, make it one at take the whole day one at time 00 and another at time 24
      const startDate = new Date(challenge.startDate.split('T')[0])
      const endDate = new Date(challenge.endDate.split('T')[0])

      // Set start date to midnight (00:00)
      startDate.setHours(0, 0, 0, 0)
      // Set end date to the end of the day (23:59:59:999)
      endDate.setHours(23, 59, 59, 999)
      return {
        startDate,
        endDate,
      }
    })

  return (
    <>
      <Typography component="h1" variant="h4" align="center">
        Add a new challenge
        <Link to={`/competitions/${id}`}>
          <LinkIcon />
        </Link>
      </Typography>
      {!isLoadingCompetition ? (
        <Paper className={classes.paper}>
          <ChallengeForm
            // @ts-expect-error legacy
            challenge={challenge}
            onChange={handleChange}
            onSave={saveChallenge}
            reservedDates={reservedDates}
          />
        </Paper>
      ) : (
        <CircularProgress className={classes.progress} />
      )}
    </>
  )
}

export default withSnackbar(withRouter(withStyles(styles)(ChallengesAdd)))
