import Checkbox from '@material-ui/core/Checkbox'
import TablePagination from '@material-ui/core/TablePagination'
import MaterialTable from 'material-table'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { DeleteGame } from './Delete'

const pageTitle = 'Games'
const columns = [
  { title: 'Title', field: 'title' },
  { title: 'Ref', field: 'referenceSource' },
  { title: 'Free', field: 'isFree', type: 'boolean' },
  {
    title: 'Published',
    field: 'state',
    type: 'boolean',
    render: (rowData) => (
      <Checkbox checked={rowData.state === 'published'} disabled />
    ),
  },
]
const options = {
  sorting: true,
  columnsButton: true,
  exportButton: true,
  // filtering: true,
  emptyRowsWhenPaging: false,
  pageSize: 10,
  actionsColumnIndex: -1,
}

// The pagination component is not right with rtl
const components = {
  Pagination: (props) => (
    <TablePagination {...props} style={{ direction: 'ltr' }} />
  ),
}

class GameTable extends React.Component {
  state: any = {
    deleteGame: null,
  }

  props: any

  actions = [
    {
      icon: 'edit',
      tooltip: 'Edit game',
      onClick: (e, row) => {
        this.handleEdit(e, row)
      },
    },
    {
      icon: 'delete',
      tooltip: 'Delete game',
      onClick: (e, row) => {
        this.handleDelete(e, row)
      },
    },
  ]

  handleEdit = (e, rowData) => {
    const { history } = this.props
    history.push(`/games/${rowData._id}`)
  }

  handleDelete = (e, rowData) => {
    this.openDelete(rowData)
  }

  handleAfterDelete = () => {
    const { onDelete } = this.props
    this.closeDelete()
    if (onDelete) onDelete()
  }

  openDelete = (rowData) => {
    this.setState({ deleteGame: rowData })
  }

  closeDelete = () => {
    this.setState({ deleteGame: null })
  }

  render() {
    const { deleteGame } = this.state
    const { data } = this.props
    return (
      <React.Fragment>
        <MaterialTable
          // @ts-expect-error legacy
          columns={columns}
          data={data}
          title={pageTitle}
          options={options}
          components={components}
          actions={this.actions}
        />
        <DeleteGame
          game={deleteGame}
          forceOpen={!!deleteGame}
          onClose={this.closeDelete}
          onAfterDelete={this.handleAfterDelete}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(GameTable)
