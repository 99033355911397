import { Paper, Typography, withStyles } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import CategoryForm from '../../components/categories/CategoryForm'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'
import { CategoryDetailStyle as styles } from './CategoriesStyle'

const CategoryDetail = (props: any) => {
  const { enqueueSnackbar, match, classes } = props
  const history = useHistory()
  const [category, setCategory]: any = useState({})

  const fetchData = useCallback(async () => {
    const { slug } = match.params
    const data = await Helper.ApiRequest(`/categories/${slug}`)
    setCategory({ ...data })
  }, [match, setCategory])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const onSuccess = () => {
    history.push('/categories')
  }

  const getSaveRequest = async () => {
    const { slug } = match.params
    const path = `/categories/${slug}`
    const method = 'PUT'
    const body = JSON.stringify(category)
    return await Helper.ApiRequest(path, { method, body })
  }

  const saveCategory = (parentCategories) => {
    if (!category.title) {
      enqueueSnackbar('Category Name is required', { variant: 'error' })
      throw new Error('Category Name is required')
    }
    if (
      parentCategories.find(
        (parentCategory) =>
          parentCategory.reference ===
          category.title.trim().replace(/\s+/gim, '_').toLowerCase(),
      )
    ) {
      enqueueSnackbar('Category Name already exists', { variant: 'error' })
      throw new Error('Category Name already exists')
    }
    const promise = getSaveRequest()
    doAndSnack(promise, enqueueSnackbar, {
      success: 'Successfully save category.',
      fail: 'Failed to save category',
      onSuccess,
    })
  }

  const handleChane = (data) => {
    setCategory((prevData) => ({ ...prevData, ...data }))
  }

  return (
    <>
      <Typography component="a" variant="h4" align="center">
        EDIT CATEGORY
      </Typography>
      <Paper className={classes.root}>
        <CategoryForm
          // @ts-expect-error legacy
          onSave={saveCategory}
          onChange={handleChane}
          category={category}
          editMode={true}
        />
      </Paper>
    </>
  )
}

export default withSnackbar(withRouter(withStyles(styles)(CategoryDetail)))
