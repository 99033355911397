import { Grid } from '@material-ui/core'
import React, { memo } from 'react'

const FilePreview = memo(({ fileUrl, image, classes }: any) => {
  return (
    <Grid item container sm={8} md={6} direction="column" alignItems="center">
      {image ? (
        <img className={classes.imgDisplay} src={fileUrl} alt="uploaded file" />
      ) : (
        <video className={classes.imgDisplay} controls>
          <source src={fileUrl} />
        </video>
      )}
    </Grid>
  )
})

export default FilePreview
