import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { memo } from 'react'

const ChapterField = memo(
  ({ chapters, classes, id, onChange, disabled }: any) => {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="ch-simple">Chapter</InputLabel>
        <Select
          disabled={disabled}
          value={id}
          onChange={onChange}
          inputProps={{
            id: 'ch-simple',
          }}
        >
          {chapters.map((ch) => {
            return (
              <MenuItem key={ch._id} value={ch._id}>
                {ch.title}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  },
)

export default ChapterField
