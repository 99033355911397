import config from '../config'

export const assetServer = config.minioBucket

export const isInServer = (url) => url.indexOf(assetServer) === 0

export const getFileName = (url) => url.split('/').reverse()[0]

export const getPath = (url) => url.replace(assetServer, '')

export const checkUrlIsImage = (url) =>
  url.match(/\.(jpeg|jpg|gif|png)$/) != null
