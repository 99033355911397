import React, { useEffect, useState } from 'react'
import {
  Paper,
  Typography,
  withStyles,
  Collapse,
  TableRow,
  TableCell,
  IconButton,
  TableHead,
  TableBody,
  Table,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

import { withRouter } from 'react-router-dom'
import { SettingsStyle as styles } from './style'
import LocalNotificationForm from './LocalNotificationForm'
import Helper from '../../utils/Helper'
import { withSnackbar } from 'notistack'
import { doAndSnack } from '../../utils/snackbar'

const LocalNotification = (props) => {
  const { enqueueSnackbar, classes } = props
  const [localNotificationData, setLocalNotificationData] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const data = await Helper.ApiRequest('/local-notification')
      setLocalNotificationData(data)
    } catch (error) {
      const { message } = error
      if (message === 'المعذرة. لقد حدث عطل بالخادم') {
        console.error('Problem with server')
      } else {
        console.error(message)
      }
    }
  }

  const doUpdateData = (localNotification) => {
    if (!Helper.isJsonString(localNotification)) return
    const body = JSON.stringify(localNotification)

    const path = `/local-notification/${localNotification._id}`
    const method = 'PUT'
    const promise = Helper.ApiRequest(path, {
      method,
      body,
    })

    doAndSnack(promise, enqueueSnackbar, {
      success: 'Succesfully update the data',
      fail: 'Failed to update the data',
    })
  }

  return (
    <>
      <Typography component="h1" variant="h5" align="center">
        Manage Local Notification
      </Typography>
      <Paper className={classes.paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell colSpan={2}>Local Notification</TableCell>
              {/* <TableCell>Status</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {localNotificationData.map((row) => (
              <Row key={row.name} row={row} handleUpdate={doUpdateData} />
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

export default withSnackbar(withRouter(withStyles(styles)(LocalNotification)))

function Row(props: any) {
  const { row, handleUpdate } = props
  const [open, setOpen]: any = React.useState(false)

  const [data, setData]: any = useState({ ...row })

  return (
    <React.Fragment>
      {/* @ts-expect-error legacy */}
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            // @ts-expect-error legacy
            size="small"
            onClick={() => {
              setOpen(!open)
            }}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {data.type}
        </TableCell>
        <TableCell colSpan={2}>
          {/* <Switch
          //   checked={feature.status}
          //   onChange={e => handleSwitch(e, feature)}
          /> */}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <LocalNotificationForm
              localNotification={data}
              handleUpdate={handleUpdate}
              setData={setData}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     id: PropTypes.number.isRequired,
//     _id: PropTypes.string.isRequired,
//     title: PropTypes.string.isRequired,
//     body: PropTypes.string.isRequired,
//     time: PropTypes.string.isRequired,
//     status: PropTypes.bool.isRequired,
//   }).isRequired,
// }
