import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs/Tabs'
import Tab from '@material-ui/core/Tab'
import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import { withStyles, withTheme } from '@material-ui/core/styles'
import Helper from '../../utils/Helper'
import { withDrawerContext } from '../../contexts/drawer-context'
import { Paper, Fab } from '@material-ui/core'
import EpisodeForm from '../../components/EpisodeForm'
import ChapterTable from '../../components/ChapterTable'
import { withSnackbar } from 'notistack'
import { doAndSnack } from '../../utils/snackbar'
import GameTable from '../../components/GameTable'
import { move } from '../../utils/array'
import BookTable from '../../components/BookTable'
import { EpisodeDetailStyles as styles } from './EpisodesStyle'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const EpisodeDetail = (props) => {
  const tabs = ['details', 'chapters', 'games', 'books']

  const {
    match,
    // drawerContext,
    // theme,
    enqueueSnackbar: snack,
    classes,
  } = props
  const tabIndex = tabs.findIndex((v) => v === match.params.tab)

  const [activities, setActivities] = useState([])
  const [title, setTitle] = useState('')
  const [episode, setEpisode]: any = useState({})
  const [tabValue, setTabValue] = useState(tabIndex === -1 ? 0 : tabIndex)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateIndicator, setUpdateIndicator] = useState(() => {})

  const history = useHistory()

  const forVisitor = episode?.forVisitor
  // const prevDrawerContext = useRef(drawerContext)

  // useEffect(() => {
  //   if (drawerContext.open !== prevDrawerContext.current.open) {
  //     // We execute updateIndicator two times, one at the begining of the
  //     // animation and one at the end
  //     // because the calculation will not be exact if it only done at the begining
  //     // and if it is only at the end there is time where the indicator is
  //     // out of the screen
  //     updateIndicator()
  //     const timeout = setTimeout(
  //       updateIndicator,
  //       theme.transitions.duration.leavingScreen
  //     )
  //     return () => {
  //       clearTimeout(timeout)
  //     }
  //   }
  //   prevDrawerContext.current = drawerContext
  // }, [drawerContext])

  const fetchData = async () => {
    const { slug } = match.params
    const [episode] = await Helper.ApiRequest(
      `/episodes?slug=${slug}&populates=activities`,
    )
    const title = getEpisodeTitle(episode)
    setEpisode({
      ...episode,
    })
    setTitle(title)
    // We cannot take episodes.activities because it is not up to date
    const activities = await Helper.ApiRequest(
      `/episodes/${episode._id}/activities`,
    )
    setActivities(activities)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (episode.isAnon) {
      setEpisode((prevEp) => ({ ...prevEp, isFree: true }))
    }
  }, [episode.isAnon])

  const getAction = (actions) => {
    setUpdateIndicator(actions.updateIndicator)
  }

  const handleChangeTab = (e, tabValue) => {
    history.push(`/episodes/${episode.slug}/${tabs[tabValue]}`)
    setTabValue(tabValue)
  }

  const saveEpisode = async () => {
    const slug = episode.slug

    if (!episode.series) {
      episode.series = null
    } else if (
      episode.path._id !== (episode.series.path._id || episode.series.path)
    ) {
      episode.series = null
    }

    if (!episode.title) {
      snack('Title is required', { variant: 'error' })
      return
    }

    if (!episode.slug) {
      snack('Slug is required', { variant: 'error' })
      return
    }

    if (!episode.isAnon && episode.order <= 0) {
      snack('Order must be greater than 0', { variant: 'error' })
      return
    }

    if (episode.series) {
      const { order, series } = episode
      const seriesData = await Helper.ApiRequest(`/series/${series._id}`)
      const filteredEpisodeBefore = seriesData.episodes.filter(
        (e) => e.order <= order && e._id !== episode._id && !e.isAnon,
      )
      const filteredEpisodeAfter = seriesData.episodes.filter(
        (e) =>
          e.order > order && e._id !== episode._id && e.isFree && !e.isAnon,
      )
      if (filteredEpisodeBefore.length > 0 && episode.isFree) {
        if (!filteredEpisodeBefore.some((e) => e.isFree)) {
          snack('Free episode need to be at the top order of series.', {
            variant: 'error',
          })
          return
        }
        if (filteredEpisodeBefore.some((e) => !e.isFree)) {
          snack('Cannot have episode free between paid episode.', {
            variant: 'error',
          })
          return
        }
      }
      if (!episode.isFree && filteredEpisodeAfter.length > 0) {
        snack('Cannot have episode free between paid episode.', {
          variant: 'error',
        })
        return
      }
    }

    const promise = Helper.ApiRequest(`/episodes/${episode._id}`, {
      method: 'PUT',
      body: JSON.stringify(episode),
    })
    const onSuccess = () => {
      history.push(`/episodes/${slug}/${tabs[tabValue]}`)
      fetchData()
    }
    doAndSnack(promise, snack, {
      success: 'Episode succesfully saved',
      fail: 'Failed to save episode',
      onSuccess,
    })
  }

  const moveChapter = (id, diff) => {
    const { chapters } = episode
    const index = chapters.findIndex((c) => id === c._id)
    const newIndex = index + diff
    if (newIndex < 0 || newIndex >= chapters.length) return
    episode.chapters = move(index, newIndex, ...chapters)
    setEpisode({
      ...episode,
    })
    saveEpisode()
  }

  const handleUpChapter = (chapter) => {
    moveChapter(chapter._id, -1)
  }

  const handleDownChapter = (chapter) => {
    moveChapter(chapter._id, 1)
  }

  const handleAddChapter = () => {
    history.push(`/chapters/add?episode=${episode.slug}`)
  }

  const handleDeleteChapter = () => {
    fetchData()
  }

  const handleChange = (episode) => {
    setEpisode({
      ...episode,
    })
  }

  const handleAddGame = () => {
    history.push(`/games/add?episode=${episode.slug}`)
  }

  const handleAddBook = () => {
    history.push(`/books/add?episode=${episode.slug}`)
  }

  if (Object.keys(episode).length < 0) {
    return null
  }

  const { chapters } = episode || {}

  return (
    <>
      <Typography component="h1" variant="h4" align="center">
        {title}
      </Typography>
      <Paper className={classes.paper}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          action={getAction}
        >
          <Tab label="Details" />
          <Tab label="Chapters" />
          <Tab label="Games" />
          <Tab label="Book" />
        </Tabs>

        {tabValue === 0 && Object.values(episode).length > 0 && (
          <TabContainer>
            <EpisodeForm
              // @ts-expect-error legacy
              episode={episode}
              onChange={handleChange}
              onSave={saveEpisode}
              editMode={true}
              isVisitor={forVisitor}
            />
          </TabContainer>
        )}
        {tabValue === 1 && (
          <ChaptersTab
            chapters={chapters}
            onAdd={handleAddChapter}
            onDelete={handleDeleteChapter}
            onUp={handleUpChapter}
            onDown={handleDownChapter}
          />
        )}
        {tabValue === 2 && (
          <GamesTab
            games={
              activities
                ? activities.filter(({ category }) => category === 'game')
                : []
            }
            onAdd={handleAddGame}
            onDelete={handleDeleteChapter}
          />
        )}
        {tabValue === 3 && (
          <BooksTab
            books={
              Array.isArray(activities) && activities
                ? activities.filter(({ category }) => category === 'book')
                : []
            }
            onAdd={handleAddBook}
            onDelete={handleDeleteChapter}
          />
        )}
      </Paper>
    </>
  )
}

EpisodeDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(
  // @ts-expect-error legacy
  withDrawerContext(withRouter(withTheme()(withStyles(styles)(EpisodeDetail)))),
)

function getEpisodeTitle({ level, slug, order, forVisitor }) {
  if (forVisitor) {
    return `'Visitor Episode ${order} : ${slug}`
  }

  return `Episode ${level}.${order} : ${slug}`
}

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

const ChaptersTab = withStyles(styles)(
  ({ chapters, classes, onAdd, onDelete, onUp, onDown }: any) => {
    return (
      <TabContainer>
        <ChapterTable
          data={chapters}
          onDelete={onDelete}
          onUp={onUp}
          onDown={onDown}
        />
        <Fab
          color="secondary"
          aria-label="Add"
          className={classes.fab}
          onClick={onAdd}
        >
          <AddIcon />
        </Fab>
      </TabContainer>
    )
  },
)

const GamesTab = withStyles(styles)(
  ({ games, classes, onAdd, onDelete }: any) => {
    return (
      <TabContainer>
        <GameTable data={games} onDelete={onDelete} />
        <Fab
          color="secondary"
          aria-label="Add"
          className={classes.fab}
          onClick={onAdd}
        >
          <AddIcon />
        </Fab>
      </TabContainer>
    )
  },
)

const BooksTab = withStyles(styles)(
  ({ books, classes, onAdd, onDelete }: any) => {
    return (
      <TabContainer>
        <BookTable books={books} onDelete={onDelete} />
        <Fab
          color="secondary"
          aria-label="Add"
          className={classes.fab}
          onClick={onAdd}
        >
          <AddIcon />
        </Fab>
      </TabContainer>
    )
  },
)

// const MapTab = withStyles(styles)(({ map, classes, onAdd, onDelete }) => {
//   return (
//     <TabContainer>
//       <MapForm map={map} />
//     </TabContainer>
//   )
// })
