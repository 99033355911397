import {
  Button,
  Grid,
  InputAdornment,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { memo } from 'react'
import { OutlinedField } from './Forms'
import PDFViewer from './PDFViewer/PDFViewer'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    width: '100%',
  },

  input: {
    display: 'none',
  },

  upload: {
    margin: theme.spacing.unit,
    width: '80%',
  },
})

const PdfUploadField = memo(
  ({ classes, onUpload, value, url, title, onCancel }: any): JSX.Element => {
    return (
      <Grid direction="column" container className={classes.upload}>
        <Typography className={classes.title}>
          {title ? `Upload ${title}` : 'Upload PDF'}
        </Typography>
        <OutlinedField
          disabled
          className={classes.textField}
          value={value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <input
                  accept="application/pdf"
                  className={classes.input}
                  id="contained-button-file-pdf"
                  type="file"
                  onChange={onUpload}
                />
              </InputAdornment>
            ),
          }}
        />
        <Grid direction="row" justify="space-around" container>
          <label htmlFor="contained-button-file-pdf">
            <Button variant="contained" component="span" color="primary">
              Choose
            </Button>
          </label>
          {value && (
            <Button
              onClick={onCancel}
              variant="contained"
              component="span"
              color="primary"
            >
              Delete
            </Button>
          )}
        </Grid>

        <Grid container direction="column">
          <span>&nbsp;</span>
          <PDFViewer url={url} />
        </Grid>
      </Grid>
    )
  },
)

export default withStyles(styles)(PdfUploadField)
