import {
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { MapViewer } from './map-viewer'
import PropTypes from 'prop-types'
import React, { memo, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withChapters, withEpisodes, withPaths } from '../contexts/data-context'
import Accordion from './Accordion'
import {
  CancelButton,
  NumberField,
  OutlinedField,
  ReferenceField,
  SaveButton,
  SvgField,
  TypeField,
} from './Forms'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    width: '100%',
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  listTextField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  listNumberField: {
    width: '4em',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  table: {
    minWidth: 40,
  },
  tableCell: {
    paddingRight: 0,
    paddingLeft: 2,
    paddingBottom: 0,
    paddingTop: 0,
  },
})

const MapForm = (props) => {
  const { classes, map, episodes, chapters, paths, history, onSave, onChange } =
    props

  const {
    locations = [],
    state = 'draft',
    bg = '',
    iconActive = '',
    iconDone = '',
    kind = '',
    item = '',
  } = map

  const locationKind = kind === 'paths' ? 'episodes' : 'chapters'

  const handleCancel = () => {
    history.goBack()
  }

  const handleSave = async (e) => {
    // We don't want submit to refresh the page
    e.preventDefault()
    return onSave()
  }

  const handleChangeReference = (event) => {
    const byId = (e) => e._id === event.target.value
    switch (kind) {
      case 'paths':
        map.item = paths.find(byId)
        break
      case 'episodes':
        map.item = episodes.find(byId)
        break
      default:
        return
    }
    onChange(map)
  }
  const handleChangeLocationReference = (index) => (event) => {
    const byId = (e) => e._id === event.target.value
    switch (locationKind) {
      case 'chapters':
        locations[index].item = chapters.find(byId)
        break
      case 'episodes':
        locations[index].item = episodes.find(byId)
        break
      default:
        return
    }
    onChange(map)
  }

  const handleChange = (field) => (event) => {
    map[field] = event.target.value
    onChange(map)
  }

  const handleChangeLocation = (index, field) => (event) => {
    map.locations[index][field] = event.target.value
    onChange(map)
  }
  const handleDeleteLocation = (index) => () => {
    // Remove the element at index
    map.locations.splice(index, 1)
    onChange(map)
  }
  const handleAddLocation = (e) => {
    locations.push({})
    map.locations = locations
    onChange(map)
  }

  const referenceProps = {
    onChange: handleChangeReference,
    episodes,
    paths,
    reference: item,
    referenceSource: kind,
  }

  const typeProps = {
    value: kind,
    types: ['episodes', 'paths'],
    onChange: handleChange('kind'),
  }
  const textProps = { classes, handleChange }

  // Choose the locations to display, episodes of path or chapters of episode
  const locationItems = useMemo(
    () =>
      kind === 'paths'
        ? episodes.filter((e) => e.path._id === item._id)
        : chapters.filter((e) => e.episode._id === item._id),
    [kind, item, episodes, chapters],
  )

  const [activeLocation, setActiveLocation] = useState(null)
  const handleChangeActiveLocation = (index) => (e) => {
    if (e.target.checked) {
      setActiveLocation(locations[index])
    } else {
      setActiveLocation(locations[index - 1])
    }
  }
  const viewedIndex = locations.findIndex((l) => l === activeLocation)
  const viewedLocations = locations.slice(0, viewedIndex + 1).map((l) => l.item)

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container spacing={24} direction="row" alignItems="flex-start">
        <Grid item xs={12} sm={4}>
          <BgField {...textProps} value={bg} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <IconActiveField {...textProps} value={iconActive} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <IconDoneField {...textProps} value={iconDone} />
        </Grid>

        <Grid item xs={4}>
          <ReferenceField {...referenceProps} />
        </Grid>
        <Grid item xs={4}>
          <TypeField {...typeProps} />
        </Grid>

        <Grid item xs={4}>
          <StateField {...textProps} value={state} />
        </Grid>

        <Grid item xs={12}>
          <LocationsField
            locations={locations}
            onAdd={handleAddLocation}
            handleChange={handleChangeLocation}
            handleDelete={handleDeleteLocation}
            locationItems={locationItems}
            kind={locationKind}
            handleChangeReference={handleChangeLocationReference}
          />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} sm={12} md={3}>
            <LocationsPositions
              locations={locations}
              handleChange={handleChangeLocation}
              handleChangeViewed={handleChangeActiveLocation}
              viewedIndex={viewedIndex}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <MapViewer
              bg={bg}
              locations={locations}
              iconDone={iconDone}
              iconActive={iconActive}
              viewedLocations={viewedLocations}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={8}>
          <Grid item xs={12} sm={6}>
            <SaveButton onClick={handleSave} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CancelButton onClick={handleCancel} />
          </Grid>
        </Grid>
        {/* {map && map._id && (
            <DangerZone>
              <DeleteGame
                map={map}
                onAfterDelete={handleAfterDelete}
                component={DeleteButton}
              />
            </DangerZone>
          )} */}
      </Grid>
    </form>
  )
}

MapForm.defaultProps = {
  map: {},
}
MapForm.propTypes = {
  classes: PropTypes.object.isRequired,
  map: PropTypes.object,
  paths: PropTypes.array.isRequired,
}

export default withChapters(
  withPaths(withEpisodes(withRouter(withStyles(styles)(MapForm)))),
)

const BgField = memo(({ classes, handleChange, value }: any) => {
  return (
    <OutlinedField
      ltr
      label="Background"
      className={classes.textField}
      value={value}
      onChange={handleChange('bg')}
      required
    />
  )
})
const IconActiveField = memo(({ classes, handleChange, value }: any) => {
  return (
    <OutlinedField
      ltr
      label="Icon Active"
      className={classes.textField}
      value={value}
      onChange={handleChange('iconActive')}
      required
    />
  )
})
const IconDoneField = memo(({ classes, handleChange, value }: any) => {
  return (
    <OutlinedField
      ltr
      label="Icon Done"
      className={classes.textField}
      value={value}
      onChange={handleChange('iconDone')}
      required
    />
  )
})
const StateField = memo(({ classes, value, handleChange }: any) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="state-simple">State</InputLabel>
      <Select
        value={value}
        onChange={handleChange('state')}
        inputProps={{
          id: 'state-simple',
        }}
      >
        <MenuItem value="published">Published</MenuItem>
        <MenuItem value="draft">Draft</MenuItem>
      </Select>
    </FormControl>
  )
})

const LocationsField = withStyles(styles)((props: any) => {
  const {
    locations,
    handleChange,
    handleChangeReference,
    handleDelete,
    onAdd,
    locationItems,
    kind,
  } = props

  const panels = locations.map((location, index) => ({
    title: `Location ${index + 1}`,
    content: Location,
    contentProps: {
      location,
      index,
      handleChange,
      handleDelete,
      locationItems,
      handleChangeReference,
      kind,
    },
  }))
  return (
    <>
      {/* @ts-expect-error legacy */}
      <Accordion panels={panels} />
      <IconButton onClick={onAdd}>
        <AddIcon />
      </IconButton>
    </>
  )
})

const Location = withStyles(styles)(
  ({
    location,
    index,
    handleChange,
    handleChangeReference,
    locationItems,
    handleDelete,
    kind,
  }: any) => {
    const { normal = '', hover = '', disabled = '', item = '' } = location

    const referenceProps = {
      onChange: handleChangeReference(index),
      chapters: locationItems,
      episodes: locationItems,
      reference: item,
      referenceSource: kind,
    }

    return (
      <Grid container>
        <Grid item xs={4}>
          <ReferenceField {...referenceProps} />
        </Grid>
        <Grid item xs={12}>
          <SvgField
            label="Normal"
            value={normal}
            onChange={handleChange(index, 'normal')}
          />
        </Grid>
        <Grid item xs={12}>
          <SvgField
            label="Hover"
            value={hover}
            onChange={handleChange(index, 'hover')}
          />
        </Grid>
        <Grid item xs={12}>
          <SvgField
            label="Disabled"
            value={disabled}
            onChange={handleChange(index, 'disabled')}
          />
        </Grid>

        <IconButton aria-label="Delete" onClick={handleDelete(index)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    )
  },
)

const LocationsPositions = withStyles(styles)((props: any) => {
  const { locations, handleChange, classes, viewedIndex, handleChangeViewed } =
    props

  return (
    <Grid container item xs={12} spacing={8}>
      <Table className={classes.table} padding="dense">
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.tableCell}>
              W
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              H
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              X
            </TableCell>
            <TableCell align="center" className={classes.tableCell}>
              Y
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {locations.map(({ width, height, x, y }, index) => {
            const changeWidth = handleChange(index, 'width')
            const changeHeight = handleChange(index, 'height')
            const changeX = handleChange(index, 'x')
            const changeY = handleChange(index, 'y')
            return (
              <TableRow key={index}>
                <TableCell className={classes.tableCell} align="center">
                  <NumberField value={width} onChange={changeWidth} />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <NumberField value={height} onChange={changeHeight} />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <NumberField value={x} onChange={changeX} />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <NumberField value={y} onChange={changeY} />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <Checkbox
                    checked={viewedIndex >= index}
                    onChange={handleChangeViewed(index)}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Grid>
  )
})
