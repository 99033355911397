import { FormControl, FormControlLabel, Switch } from '@material-ui/core'
import React, { memo } from 'react'

const IsFreeField = memo(
  ({ classes, isFree, handleChange, disabled = false }: any) => {
    return (
      <FormControl className={classes.formControl}>
        <FormControlLabel
          control={
            <Switch
              checked={isFree}
              onChange={handleChange('isFree')}
              value="isFree"
              disabled={disabled}
            />
          }
          label="Free"
        />
      </FormControl>
    )
  },
)

export default IsFreeField
