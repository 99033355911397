import { OutlinedField } from '../../components/Forms'
import React from 'react'

const SlugField = ({ classes, handleChange, value, handleKeyPressed }) => {
  return (
    <OutlinedField
      label="Slug"
      onKeyDown={handleKeyPressed}
      className={classes.textField}
      value={value}
      onChange={handleChange('slug')}
      required
    />
  )
}

export default SlugField
