import React, { FC } from 'react'
import { Menu, MenuItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const ActionMenu: FC<{ open: boolean; anchorEl: null }> = ({
  open,
  anchorEl,
}) => {
  const history = useHistory()

  const handleAddSeries = () => {
    history.push('/series/add')
  }

  const handleAddEpisode = () => {
    history.push('/episodes/add')
  }
  const handleAddVisitorEpisode = () => {
    history.push('/episodes/add?visitor=true')
  }

  const handleAddChapter = () => {
    history.push('/chapters/add')
  }

  const handleAddCompetitions = () => {
    history.push('/competitions/add')
  }

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
    >
      <MenuItem onClick={handleAddSeries}>Add series</MenuItem>
      <MenuItem onClick={handleAddEpisode}>Add episode</MenuItem>
      <MenuItem onClick={handleAddVisitorEpisode}>Add visitor episode</MenuItem>
      <MenuItem onClick={handleAddChapter}>Add chapter</MenuItem>
      <MenuItem onClick={handleAddCompetitions}>Add competition</MenuItem>
    </Menu>
  )
}

ActionMenu.defaultProps = {
  open: false,
}

export default ActionMenu
