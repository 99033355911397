import React, { useState } from 'react'

import { withStyles, TextField, Grid } from '@material-ui/core'

import { withRouter } from 'react-router-dom'

import { FormStyle as styles } from './style'

import ButtonSet from '../../components/localNotification/ButtonSet'

const LocalNotificationForm = (props: any) => {
  const { classes, localNotification, handleUpdate, setData } = props

  const [loading, setLoading] = useState(false)

  const [time, setTime] = useState(localNotification.time)
  const [title, setTitle] = useState(localNotification.title)
  const [message, setMessage] = useState(localNotification.message)

  const handleSubmit = (e) => {
    e.preventDefault()

    setLoading(true)
    const newNotificationData = {
      title,
      message,
      time,
      id: localNotification.notiId,
      type: localNotification.type,
      status: localNotification.status,
      _id: localNotification._id,
    }
    handleUpdate(newNotificationData)
    setData(newNotificationData)

    setLoading(false)
  }

  const handleCancel = () => {
    setTime(localNotification.time)
    setTitle(localNotification.title)
    setMessage(localNotification.message)
  }

  return (
    <form noValidate autoComplete="off">
      <Grid
        className={classes.container}
        container
        spacing={8}
        direction="column"
        // @ts-expect-error legacy
        alignItems="right"
      >
        <TextField
          classes={classes.textField}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
          value={title}
          type="text"
          label="Notification Title"
          placeholder="Title"
          fullWidth
        />
        <br />
        <TextField
          classes={classes.textField}
          onChange={(e) => {
            setMessage(e.target.value)
          }}
          value={message}
          label="Notification Message"
          placeholder="Message"
          multiline
          fullWidth
          rows={2}
          // @ts-expect-error legacy
          maxRows={4}
        />
        <br />
        <br />
        <TextField
          id="time"
          label="Time"
          type="time"
          defaultValue={time}
          value={time}
          className={classes.timeField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          onChange={(e) => {
            setTime(e.target.value)
          }}
        />
        <ButtonSet
          classes={classes}
          onCancel={handleCancel}
          onSave={handleSubmit}
          loading={loading}
        />
      </Grid>
    </form>
  )
}
export default withRouter(withStyles(styles)(LocalNotificationForm))
