import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { withSnackbar } from 'notistack'
import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import OnboardingForm from '../../components/onboarding/OnboardingForm'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'
import { OnboardingDetailStyle as styles } from './OnboardingStyles'

const OnboardingDetail = (props: any) => {
  const { enqueueSnackbar, history, match, classes }: any = props
  const [onboarding, setOnboarding] = useState({
    title: '',
    deepLink: '',
    orderNo: 0,
    imageUrl: '',
    status: true,
  })

  const fetchOnboarding = useCallback(async () => {
    const { slug } = match.params
    const data = await Helper.ApiRequest(`/onboarding/${slug}`)
    setOnboarding({ ...data })
  }, [match, setOnboarding])

  useEffect(() => {
    fetchOnboarding()
  }, [fetchOnboarding])

  const saveOnboarding = () => {
    const [promise, onSuccess] = getSaveRequest(onboarding)
    doAndSnack(promise, enqueueSnackbar, {
      success: 'Onboarding page created successfully',
      fail: 'Failed to create onboarding page',
      onSuccess,
    })
  }

  const onSuccess = () => {
    history.push('/onboardings')
  }

  const getSaveRequest = (onboarding) => {
    const { slug } = match.params

    const path = '/onboarding/' + slug
    const method = 'PUT'
    const body = JSON.stringify(onboarding)
    const promise = Helper.ApiRequest(path, { method, body })

    return [promise, onSuccess]
  }

  const handleChange = (data) => {
    setOnboarding((prev) => ({ ...prev, ...data }))
  }

  return (
    <React.Fragment>
      <FormTitle />
      <Paper className={classes.paper}>
        <OnboardingForm
          // @ts-expect-error legacy
          onChange={handleChange}
          onSave={saveOnboarding}
          onboarding={onboarding}
          editMode={true}
        />
      </Paper>
    </React.Fragment>
  )
}

export default withSnackbar(withRouter(withStyles(styles)(OnboardingDetail)))

function FormTitle() {
  return (
    <Typography component="a" variant="h4" align="center">
      Edit Onboarding Screen
    </Typography>
  )
}
