import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { memo } from 'react'

const EpisodeField = memo(
  ({ episodes, classes, id, onChange, disabled }: any) => {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="episode-simple">Episode</InputLabel>
        <Select
          disabled={disabled}
          value={id}
          onChange={onChange}
          inputProps={{
            id: 'episode-simple',
          }}
        >
          {Array.isArray(episodes) &&
            episodes.map((ep) => {
              return (
                <MenuItem key={ep._id} value={ep._id}>
                  {ep.title}
                </MenuItem>
              )
            })}
        </Select>
      </FormControl>
    )
  },
)

export default EpisodeField
