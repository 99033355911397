import React, { memo } from 'react'
import { Button, Grid } from '@material-ui/core'
import UploadIcon from '@material-ui/icons/CloudUpload'

const UploadField = memo(({ classes, onChange, editMode }: any) => {
  return (
    <Grid item container sm={8} md={6}>
      <Grid item xs={12} sm={6}>
        <input
          className={classes.input}
          id="contained-button-file-video"
          type="file"
          onChange={onChange}
          accept="video/*"
        />
        <label htmlFor="contained-button-file-video">
          <Button
            variant="contained"
            component="span"
            className={classes.button}
            color="primary"
          >
            <UploadIcon className={classes.buttonIcon} />
            {editMode ? 'Update' : 'Upload'}
          </Button>
        </label>
      </Grid>
    </Grid>
  )
})

export default UploadField
