export const toBlob = async (src) =>
  await fetch(src).then(async (res) => await res.blob())

export const getExtension = (blob) =>
  blob.type ? blob.type.split('/')[1].split('+')[0] : ''

export const readableSize = (bytes) => {
  if (!bytes) return '0.00B'
  const e = Math.floor(Math.log(bytes) / Math.log(1024))
  return (bytes / Math.pow(1024, e)).toFixed(2) + ' KMGTP'.charAt(e) + 'B'
}
