import React, { memo } from 'react'
import { OutlinedField } from '../Forms'

const FileField = memo(({ classes, handleChange, value }: any) => {
  return (
    <OutlinedField
      ltr
      label="File"
      className={classes.textField}
      value={value}
      onChange={handleChange('file')}
    />
  )
})

export default FileField
