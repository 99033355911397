import { Paper, Typography, withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'
import { bookAddStyle as styles } from './styles'
import PropTypes from 'prop-types'
import { withSnackbar } from 'notistack'
import BookForm from '../../components/book/BookForm'

const BookAdd = (props: any) => {
  const { classes, enqueueSnackbar } = props
  const { search } = useLocation()
  const history = useHistory()

  const [book, setBook]: any = useState({
    category: 'book',
    referenceSource: 'episodes',
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    const searchParams = new URLSearchParams(search)
    const episodeParam = searchParams.get('episode')
    const [episode] = episodeParam
      ? await Helper.ApiRequest(`/episodes?slug=${episodeParam}`)
      : []
    book.reference = episode
    setBook({ ...book })
  }

  const saveBook = () => {
    const { reference, isFree } = book

    if (Object.keys(reference).length > 0 && isFree) {
      if (!reference.isFree) {
        enqueueSnackbar('You cannot link free book with paid episode.', {
          variant: 'error',
        })
        return
      }
    }

    doAndSnack(getSaveRequest(), enqueueSnackbar, {
      success: 'Book succesfully saved',
      fail: 'Failed to save book',
      onSuccess,
    })
  }

  const onSuccess = () => {
    const epslug = book.reference.slug
    history.push(`/episodes/${epslug}/books`)
  }

  const getSaveRequest = async () => {
    const path = '/activities'
    const method = 'POST'
    const body = JSON.stringify(book)
    return await Helper.ApiRequest(path, { method, body })
  }

  const handleChange = (book) => {
    setBook({ ...book })
  }

  return (
    <>
      <FormTitle />
      <Paper className={classes.paper}>
        {/*  @ts-expect-error legacy */}
        <BookForm onChange={handleChange} book={book} onSave={saveBook} />
      </Paper>
    </>
  )
}

BookAdd.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(withRouter(withStyles(styles)(BookAdd)))

const FormTitle = () => {
  return (
    <Typography component="a" variant="h4" align="center">
      Add a book
    </Typography>
  )
}
