import {
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import SaveIcon from '@material-ui/icons/Save'
import { withSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import Helper from '../../utils/Helper'
import { styles } from './style'

const PERIOD_FIELDS = {
  distributionStatus: 'distributionStatus',
  enabled: 'enabled',
}

const Period = (props) => {
  const { classes, enqueueSnackbar } = props
  const [periods, setPeriods] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchSchedules = useCallback(async () => {
    setIsLoading(true)
    const data = await Helper.ApiRequest('/schedules')
    if (data) {
      const periods = data.map((e) => {
        return {
          id: e._id,
          period: e.period,
          level: {
            title: e.level.title,
            id: e.level._id,
          },
          enabled: e.enabled,
          collapsible: false,
          distributionStatus: e.distributionStatus,
          distributions: e.distributions.map((e) => {
            return {
              id: e._id,
              day: e.day,
              type: e.type,
            }
          }),
        }
      })
      setPeriods(periods)
    }
    setIsLoading(false)
  }, [])

  useEffect(() => {
    fetchSchedules()
  }, [fetchSchedules])

  const saveSchedules = async () => {
    setIsLoading(true)
    try {
      await Promise.all(
        periods.map(async (period) => {
          const body = JSON.stringify({
            period: period.period,
            level: period.level.id,
            enabled: period.enabled,
            distributions: period.distributions,
            distributionStatus: period.distributionStatus,
          })
          await Helper.ApiRequest(`/schedules/${period.id}`, {
            method: 'PUT',
            body,
          })
        }),
      )
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      enqueueSnackbar(e.message, {
        variant: 'error',
      })
    }
  }

  const handleCollapsible = (period) => () => {
    periods.find((e) => e.id === period.id).collapsible = !period.collapsible
    setPeriods([...periods])
  }

  const handleSelect = (distribution, period) => (event) => {
    distribution.type = event.target.value
    periods.find((e) => e.id === period.id).distributions =
      period.distributions.map((e) => {
        if (e.id === distribution.id) {
          return distribution
        }
        return e
      })
    setPeriods([...periods])
  }

  const handlePeriodDay = (period) => (event) => {
    const value = parseInt(event.target.value ?? 0)
    const index = periods.findIndex((e) => e.id === period.id)
    periods[index].period = value
    if (!isNaN(value)) {
      periods[index].distributions = [...Array(value)].map((e, index) => {
        return {
          id: Math.random() * 10000,
          day: index + 1,
          type: index === 0 ? 'video' : 'free',
        }
      })
    }
    setPeriods([...periods])
  }

  const handleEnabledSwitch = (period, key) => (event) => {
    periods.find((e) => e.id === period.id)[key] = event.target.checked
    setPeriods([...periods])
  }

  return (
    <>
      <Typography component="h1" variant="h4" align="center">
        Manage Taalam Periods
      </Typography>
      <Paper className={classes.paper}>
        <Grid
          className={classes.container}
          container
          spacing={8}
          direction="column"
          alignItems="center"
        >
          <div className={classes.container}>
            {periods.map((period) => (
              <div
                style={{
                  margin: '12px',
                }}
              >
                <Grid item container alignItems="center" justify="space-around">
                  <Grid item xs={5}>
                    <TextField
                      label="period"
                      variant="outlined"
                      value={period.period}
                      disabled={!period.enabled}
                      onChange={handlePeriodDay(period)}
                      type="number"
                    />
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={period.enabled}
                      onChange={handleEnabledSwitch(
                        period,
                        PERIOD_FIELDS.enabled,
                      )}
                    />
                  </Grid>
                  {/* @ts-expect-error legacy */}
                  <Grid item xs={2} tex>
                    <Grid>
                      <Typography align="center">
                        {period.level.title}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      marginRight: '10px',
                    }}
                  >
                    <IconButton onClick={handleCollapsible(period)}>
                      {!period.collapsible ? (
                        <ArrowDownward />
                      ) : (
                        <ArrowUpward />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
                <Collapse
                  in={period.collapsible}
                  style={{
                    background: '#FAFAFA',
                  }}
                >
                  <div
                    style={{
                      margin: '30px',
                    }}
                  >
                    <Typography align="center" variant="h5">
                      Manage Distributions
                    </Typography>
                    <Grid container alignItems="center" justify="center">
                      <Grid item>
                        <Switch
                          checked={period.distributionStatus}
                          onChange={handleEnabledSwitch(
                            period,
                            PERIOD_FIELDS.distributionStatus,
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography>Distribution Status</Typography>
                      </Grid>
                    </Grid>
                  </div>
                  {period.distributions.length === 0 && (
                    <div
                      style={{
                        margin: '12px',
                      }}
                    >
                      <Typography align="center" variant="body1">
                        Please set period days
                      </Typography>
                    </div>
                  )}
                  {period?.distributionStatus &&
                    period?.distributions.map((item, index) => (
                      <div
                        style={{
                          margin: '12px',
                        }}
                      >
                        <Grid
                          item
                          container
                          alignItems="center"
                          justify="space-around"
                        >
                          <Grid item>
                            <Typography>Day {item.day}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            {index === 0 ? (
                              <Typography variant="body1">Video</Typography>
                            ) : (
                              <Select
                                value={item.type}
                                variant="outlined"
                                fullWidth
                                disabled={item.type === 'video'}
                                onChange={handleSelect(item, period)}
                              >
                                <MenuItem value="book">Book</MenuItem>
                                <MenuItem value="game">Game</MenuItem>
                                <MenuItem value="free">Free Slot</MenuItem>
                              </Select>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                </Collapse>
              </div>
            ))}
          </div>
          <Grid item xs={12} sm={3} md={2}>
            {isLoading && <CircularProgress />}
            {!isLoading && (
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                onClick={saveSchedules}
              >
                <SaveIcon className={classes.buttonIcon} />
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
// @ts-expect-error legacy
export default withRouter(withSnackbar(withStyles(styles)(Period)))
