import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import GameForm from '../../components/GameForm'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'
import { gameAddStyle as styles } from './styles'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const GameAdd = (props) => {
  const { classes, enqueueSnackbar, history } = props
  const { search } = useLocation()

  const [game, setGame]: any = useState({
    category: 'game',
    referenceSource: 'episodes',
  })

  const fetchData = async () => {
    // The episode may be given in the url
    const searchParams = new URLSearchParams(search)
    const episodeParam = searchParams.get('episode')
    const [episode] = episodeParam
      ? await Helper.ApiRequest(`/episodes?slug=${episodeParam}`)
      : []
    game.reference = episode
    setGame({ ...game })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveGame = async () => {
    const { reference, isFree }: any = game

    if (Object.keys(reference).length > 0 && isFree) {
      if (!reference.isFree) {
        enqueueSnackbar('You cannot link free game with paid episode.', {
          variant: 'error',
        })
        return
      }
    }

    await doAndSnack(getSaveRequest(), enqueueSnackbar, {
      success: 'Game succesfully saved',
      fail: 'Failed to save game',
      onSuccess,
    })
  }

  const onSuccess = () => {
    const epSlug = game.reference.slug
    history.push(`/episodes/${epSlug}/games`)
  }

  const getSaveRequest = async () => {
    const path = '/activities'
    const method = 'POST'
    const body = JSON.stringify(game)
    return await Helper.ApiRequest(path, { method, body })
  }

  const handleChange = (game) => {
    setGame({ ...game })
  }

  return (
    <>
      <FormTitle />
      <Paper className={classes.paper}>
        {/* @ts-expect-error legacy */}
        <GameForm onChange={handleChange} game={game} onSave={saveGame} />
      </Paper>
    </>
  )
}

GameAdd.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(withRouter(withStyles(styles)(GameAdd)))

function FormTitle() {
  return (
    <Typography component="a" variant="h4" align="center">
      Add a game
    </Typography>
  )
}
