import React, { memo } from 'react'
import { OutlinedField } from '../Forms'

const ContentField = memo(({ classes, handleChange, value }: any) => {
  return (
    <OutlinedField
      ltr
      label="Content"
      className={classes.textField}
      value={value}
      onChange={handleChange('url')}
      required
    />
  )
})

export default ContentField
