import React, { useState } from 'react'
import { Grid, withStyles, Typography } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import { CancelButton, DeleteButton, SaveButton } from './Forms'
import { DangerZone } from './Grids'
import TitleField from './field/TitleField'
import StateField from './field/StateField'
import { DeleteChallenge } from './Delete'
import ContentField from './field/ContentField'
import Helper from '../utils/Helper'
import GameFrame from './GameFrame'
import Calendar from '@demark-pro/react-booking-calendar'
import { snackProgressBar } from '../utils/snackbar'
import { OVERLAP_DATE_MESSAGE } from '../constants/ErrorMessages'
import SlugField from './field/SlugField'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    // textAlign: 'center',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  listTextField: {
    width: 'calc(100% - 50px)', // We give place to the icon
    marginTop: '0',
    marginBottom: '0',
    paddingTop: '0',
  },
})

const ChallengeForm = (props) => {
  const {
    history,
    classes,
    challenge,
    paths,
    onSave,
    onChange,
    reservedDates,
    enqueueSnackbar,
  } = props

  const { title, state, competition, startDate, endDate, _id, url, slug } =
    props?.challenge

  const handleChange = (field) => (event) => {
    challenge[field] = event.target.value

    if (field === 'title' && event.target.value.length > 100) return

    onChange(challenge)
  }

  const handleSave = async (e) => {
    e.preventDefault()
    return onSave()
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleSlugSpaceBar = (event) => {
    if (event.key === ' ') {
      event.preventDefault()
      snackProgressBar(
        enqueueSnackbar,
        { success: '', fail: 'Slug cannot have space' },
        false,
      )
    }
  }

  const textProps = { classes, handleChange }

  // Calendar selected dates
  const [selectedDates, setSelectedDates] = useState([startDate, endDate])
  const handleChangeDate = (e) => {
    setSelectedDates(e)

    if (!Array.isArray(e) || e?.length === 0) return

    let startDate = ''
    let endDate = ''

    if (e.length === 1) {
      // If only one date is selected, assign it to both startDate and endDate
      startDate = endDate = formatToDate(e[0])
    } else if (e.length > 1) {
      // If two dates are selected, assign them to startDate and endDate respectively
      startDate = formatToDate(e[0])
      endDate = formatToDate(e[1])
    }

    challenge.startDate = startDate
    challenge.endDate = endDate

    onChange(challenge)
  }

  const formatToDate = (date) => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // getMonth() is zero-based
    const day = date.getDate().toString().padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid
        container
        spacing={24}
        alignItems="flex-start"
        justify="space-around"
      >
        <Grid item xs={12} sm={6} md={9}>
          <TitleField {...textProps} value={title} />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <SlugField
            {...textProps}
            value={slug}
            handleKeyPressed={handleSlugSpaceBar}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={1}></Grid>

        <Grid item xs={12} sm={3} md={1}>
          <StateField {...textProps} value={state} />
        </Grid>

        <Grid item xs={12} sm={6} md={5} style={{ textAlign: 'center' }}>
          <Typography component="h1" variant="h6" align="center">
            Challenge date must be within the range of (yyyy-MM-dd):{' '}
            <b>{competition?.startDate?.split('T')?.[0]}</b> until{' '}
            <b>{competition?.endDate?.split('T')?.[0]}</b>
          </Typography>

          {challenge?.isParticipated && <b>Challenge has been participated</b>}
          <Calendar
            style={{ direction: 'ltr' }}
            selected={selectedDates}
            onChange={handleChangeDate}
            disabled={challenge?.isParticipated}
            range={true}
            reserved={reservedDates}
            onOverbook={(e, err) =>
              snackProgressBar(
                enqueueSnackbar,
                { success: '', fail: OVERLAP_DATE_MESSAGE },
                false,
              )
            }
          />
        </Grid>

        <Grid container direction="column" item xs={12} sm={5}>
          <Grid item xs={12}>
            <ContentField {...textProps} value={url} />
          </Grid>
          <Grid item xs={12}>
            {url && (
              <GameFrame
                src={Helper.MediaURL(url)}
                token={Helper.token()}
                gameId={'game-id'}
                // @ts-expect-error legacy
                api={Helper.apiURL}
                frameBorder="0"
                allowFullScreen
                width="100%"
                height="500px"
              />
            )}
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={12} sm={3} md={2}>
            <SaveButton onClick={handleSave} />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <CancelButton onClick={handleCancel} />
          </Grid>
        </Grid>

        {challenge?._id && (
          <DangerZone>
            {challenge?.isParticipated ? (
              <Typography variant="h5">
                لايمكن حذف السباق طالما تسابق عليه أطفال
              </Typography>
            ) : (
              <DeleteChallenge
                challenge={challenge}
                onAfterDelete={() => history.goBack()}
                component={DeleteButton}
              />
            )}
          </DangerZone>
        )}
      </Grid>
    </form>
  )
}

export default withSnackbar(withRouter(withStyles(styles)(ChallengeForm)))
