import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import withRoot from '../withRoot'
import Header from './Header'
import SideBar from './SideBar'
import { DrawerContext } from '../contexts/drawer-context'

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
})

class Layout extends React.Component {
  state: any = {
    drawerOpen: false,
  }

  props: any

  handleDrawerToggle = () => {
    this.setState((state) => ({ drawerOpen: !this.state.drawerOpen }))
  }

  render() {
    const { classes, children } = this.props
    const { drawerOpen } = this.state
    return (
      <div className={classes.root}>
        <DrawerContext.Provider value={{ open: drawerOpen }}>
          {/* @ts-expect-error legacy */}
          <Header onOpenDrawer={this.handleDrawerToggle} />
          {/* @ts-expect-error legacy */}
          <SideBar onCloseDrawer={this.handleDrawerToggle} />
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {/* this is the main content of the page */}
            {children}
          </main>
        </DrawerContext.Provider>
      </div>
    )
  }
}
// @ts-expect-error legacy
Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withRoot(withStyles(styles, { withTheme: true })(Layout))
