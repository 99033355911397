const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    width: '100%',
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
})

export default styles
