const commonStyle: any = (theme) => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
  },
})

export const bookAddStyle: any = (theme) => ({
  ...commonStyle(theme),
})

export const bookDetailStyle: any = (theme) => ({
  ...commonStyle(theme),
})
