import { Button, Grid, InputAdornment, Typography } from '@material-ui/core'
import React, { memo } from 'react'
import { OutlinedField } from '../Forms'

const UploadField = memo(({ classes, onUpload, value }: any) => {
  return (
    <Grid direction="column" container className={classes.upload}>
      <Typography className={classes.title}>Upload Icon</Typography>
      <OutlinedField
        disabled
        className={classes.textField}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <input
                accept="image/*,video/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                onChange={onUpload}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" component="span" color="primary">
                  Choose
                </Button>
              </label>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  )
})

export default UploadField
