import { Button, CircularProgress, Grid } from '@material-ui/core'
import React, { memo } from 'react'

const ButtonSection = memo(
  ({ classes, onSave, loading, onCancel, editMode }: any) => {
    return (
      <Grid direction="row" container className={classes.upload}>
        {!loading ? (
          <>
            <Grid direction="row" container className={classes.upload}>
              <Button
                variant="contained"
                component="span"
                className={classes.button}
                color="secondary"
                onClick={onSave}
              >
                {!editMode ? 'Create' : 'Update'}
              </Button>
              <Button
                variant="contained"
                component="span"
                className={classes.button}
                color="default"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </Grid>
          </>
        ) : (
          <CircularProgress className={classes.progress} />
        )}
      </Grid>
    )
  },
)

export default ButtonSection
