const styles: any = (theme) => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    width: '100%',
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
  container: {
    margin: theme.spacing.unit,
  },
  imgDisplay: {
    width: 'auto',
    height: '200px',
    objectFit: 'cover',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
})

export default styles
