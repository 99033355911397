import React from 'react'
import {
  MuiThemeProvider,
  createMuiTheme,
  createGenerateClassName,
} from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'
import green from '@material-ui/core/colors/green'
import CssBaseline from '@material-ui/core/CssBaseline'
import rtl from 'jss-rtl'
import { JssProvider, jss } from 'react-jss'

// Configure JSS
jss.use(rtl())

const generateClassName = createGenerateClassName()

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  direction: 'rtl',
  palette: {
    primary: blue,
    secondary: green,
  },
  typography: {
    fontFamily: "'Tajawal', sans-serif",
    useNextVariants: true,
  },
})

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <JssProvider jss={jss} generateClassName={generateClassName}>
          <Component {...props} />
        </JssProvider>
      </MuiThemeProvider>
    )
  }

  return WithRoot
}

export default withRoot
