import { Grid, withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { withChapters, withEpisodes } from '../../contexts/data-context'
import CategoryField from './CategoryFields'
import DescriptionField from './DescriptionFields'
import FileField from './FileField'
import IsFreeField from './IsFreeField'
import ReferenceField from './ReferenceField'
import ReferenceSourceField from './ReferenceSourceField'
import StateField from './StateFields'
import styles from './style'
import TitleField from './TitleField'
import ContentField from './ContentField'
import CardControl from '../ImageControl'
import GameFrame from '../GameFrame'
import { CancelButton, DeleteButton, SaveButton } from '../Forms'
import { DangerZone } from '../Grids'
import { DeleteBook } from '../Delete'
import Helper from '../../utils/Helper'
import { assetServer } from '../../utils/url'
import PdfUploadField from '../PdfUploadField'

const BookForm = (props: any) => {
  const { onSave, onChange, book, episodes, chapters, classes } = props
  const history = useHistory()

  const [handbookFile, setHandbookFile] = useState(null)

  const {
    _id,
    title = '',
    slug = '',
    state = 'draft',
    thumbnail = '',
    isFree = '',
    content = '',
    reference = '',
    referenceSource = 'episodes',
    category = 'book',
    description = '',
    file = '',
    handbook = '',
  } = book

  const handleCancel = () => {
    history.goBack()
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      if (handbookFile) book.handbook = await handleUploadPdf(handbookFile)
    } catch (error) {
      console.log(error)
    }

    onSave()
  }

  const handleChangeReference = (event) => {
    const { value } = event.target
    const byId = (v) => v._id === value
    let reference

    switch (book.referenceSource) {
      case 'chapters':
        reference = chapters.find(byId)
        break
      case 'episodes':
        reference = episodes.find(byId)
        break
      default:
        return
    }

    onChange({ ...book, reference })
  }

  const handleChange = (field) => (event) => {
    book[field] = event.target.value
    onChange({ ...book })
  }

  const handleChangeUrl = (field) => (value) => {
    book[field] = value
    onChange({ ...book })
  }

  const handleChangeChecked = (field) => (event) => {
    book[field] = event.target.checked
    onChange({ ...book })
  }

  const handleAfterDelete = () => {
    history.push(`/episodes/${book.reference.slug}/books`)
  }

  const handlePdfFileUpload = (event) => {
    const uploadedFile = event.target.files[0]

    setHandbookFile(uploadedFile)
  }

  const cancelUploadPdf = () => {
    if (handbookFile) {
      setHandbookFile(null)
    }

    if (handbook) {
      delete book.handbook
      onChange({ ...book, handbook: '' })
    }
  }

  const handleUploadPdf = async (file) => {
    const uploadName = 'pdf/' + file.name
    const formData = new FormData()
    formData.append('upload', file)
    await Helper.uploadAsset(uploadName, formData)
    return assetServer + uploadName
  }

  const textProps = { classes, handleChange }
  const checkProps = { classes, handleChange: handleChangeChecked }
  const referenceProps = {
    classes,
    onChange: handleChangeReference,
    episodes,
    chapters,
    reference,
    referenceSource,
  }

  return (
    <form className={classes.root}>
      <Grid container spacing={24} direction="row" alignItems="flex-start">
        <Grid container item direction="row" xs={12}>
          <Grid item xs={4}>
            <ReferenceField {...referenceProps} />
          </Grid>
          <Grid item xs={4}>
            <ReferenceSourceField {...textProps} value={referenceSource} />
          </Grid>
          <Grid item xs={4}>
            <CategoryField {...textProps} value={category} />
          </Grid>
        </Grid>

        <Grid container item direction="column" xs={12} sm={6}>
          <Grid item xs={12}>
            <TitleField {...textProps} value={title} />
          </Grid>
          <Grid item xs={12}>
            <DescriptionField {...textProps} value={description} />
          </Grid>
          <Grid item xs={12}>
            <FileField {...textProps} value={file} />
          </Grid>
          <Grid container item direction="row">
            <Grid item xs={6}>
              <StateField {...textProps} value={state} />
            </Grid>
            {!reference?.forVisitor && (
              <Grid item xs={6}>
                <IsFreeField
                  {...checkProps}
                  value={isFree}
                  disabled={reference.isAnon}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid container direction="column" item xs={12} sm={6}>
          <Grid item xs={12}>
            <ContentField {...textProps} value={content} />
          </Grid>
          <Grid item xs={12}>
            {content && (
              <GameFrame
                src={Helper.MediaURL(content)}
                token={Helper.token()}
                gameId={_id}
                // @ts-expect-error legacy
                api={Helper.apiURL}
                frameBorder="0"
                allowFullScreen
                width="100%"
                height="500px"
              />
            )}
          </Grid>
        </Grid>

        <Grid container direction="row">
          <Grid item xs={12} sm={6}>
            <CardControl
              // @ts-expect-error legacy
              url={thumbnail}
              onChangeUrl={handleChangeUrl('thumbnail')}
              title="Thumbnail"
              uploadDefaultPrefix="thumbs"
              uploadDefaultName={`th-${slug}`}
              onUpload={onSave}
            />
          </Grid>

          {!reference.isAnon && !reference?.forVisitor && (
            <Grid item xs={12} sm={6}>
              <PdfUploadField
                classes={classes}
                onUpload={handlePdfFileUpload}
                title={'Handbook'}
                value={handbookFile ? handbookFile.name : handbook}
                url={handbookFile ?? handbook}
                onCancel={cancelUploadPdf}
              />
            </Grid>
          )}
        </Grid>

        <Grid item container xs={12} spacing={8}>
          <Grid item xs={12} sm={6}>
            <SaveButton onClick={handleSubmit} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CancelButton onClick={handleCancel} />
          </Grid>
        </Grid>
        {book && book._id && (
          <DangerZone>
            <DeleteBook
              book={book}
              onAfterDelete={handleAfterDelete}
              component={DeleteButton}
            />
          </DangerZone>
        )}
      </Grid>
    </form>
  )
}

export default withChapters(
  withEpisodes(withRouter(withStyles(styles)(BookForm))),
)
