import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import ChapterForm from '../../components/chapter/ChapterForm'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'
import { chapterAddStyle as styles } from './styles'

const ChapterAdd = (props: any) => {
  const { classes, enqueueSnackbar } = props
  const [chapter, setChapter]: any = useState({})
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)

  const fetchData = async () => {
    const { search } = history.location
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    })
    const { episode: episodeParam }: any = params

    const [episode] = episodeParam
      ? await Helper.ApiRequest(`/episodes?slug=${episodeParam}`)
      : []
    chapter.episode = episode
    setChapter(chapter)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveChapter = async () => {
    const { isFree, episode, slug, title } = chapter

    if (!episode) {
      enqueueSnackbar('Please link chapter with an episode', {
        variant: 'error',
      })
      return
    }
    if (!title) {
      enqueueSnackbar('Title is required!', {
        variant: 'error',
      })
      return
    }

    if (!slug) {
      enqueueSnackbar('Slug is required!', {
        variant: 'error',
      })
      return
    }

    if (episode && isFree) {
      if (!episode.isFree) {
        enqueueSnackbar('You cannot link free chapter with paid episode.', {
          variant: 'error',
        })
        return
      }
    }

    const [promise, onSuccess] = getSaveRequest(chapter)
    doAndSnack(promise, enqueueSnackbar, {
      success: 'Chapter succesfully saved',
      fail: 'Failed to save chapter',
      onSuccess,
    })
  }

  const getSaveRequest = (data) => {
    const epslug = data?.episode?.slug
    const path = '/chapters'
    const method = 'POST'
    const body = JSON.stringify(data)
    const promise = Helper.ApiRequest(path, {
      method,
      body,
    })

    const onSuccess = () => {
      history.push(`/episodes/${epslug}/chapters`)
    }

    return [promise, onSuccess]
  }

  const handleChange = (data) => {
    setChapter({
      ...data,
    })
  }

  return (
    <>
      <FormTitle />
      <Paper className={classes.paper}>
        {!isLoading && (
          <ChapterForm
            // @ts-expect-error legacy
            onChange={handleChange}
            onSave={saveChapter}
            chapter={chapter}
          />
        )}
      </Paper>
    </>
  )
}

ChapterAdd.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(withRouter(withStyles(styles)(ChapterAdd)))

function FormTitle() {
  return (
    <Typography component="a" variant="h4" align="center">
      Add a chapter
    </Typography>
  )
}
