import React, { memo } from 'react'
import { OutlinedField } from '../Forms'

const TitleField = memo(({ classes, handleChange, value }: any) => {
  return (
    <OutlinedField
      label="Title"
      className={classes.textField}
      value={value}
      onChange={handleChange('title')}
      required
    />
  )
})

export default TitleField
