import React, { Component } from 'react'
import Config from '../config'
import { Typography } from '@material-ui/core'
const api = Config.apiURL
export default class GameFrame extends Component {
  props: any
  states: any
  handleLoad = (e) => {
    const { gameId, token } = this.props
    const iframeWindow = e.target.contentWindow
    iframeWindow.postMessage({ gameId, token, api }, '*')
  }

  isValidUrl = (url) => {
    const regexPattern = new RegExp('^(http|https)://', 'i')
    return regexPattern.test(url)
  }

  render() {
    const props = { ...this.props }
    const { src } = this.props

    if (!this.isValidUrl(src)) {
      return (
        <Typography variant="body1" color="error" align="center">
          Invalid URL
        </Typography>
      )
    }

    delete props.token
    delete props.gameId
    return <iframe title="game-iframe" onLoad={this.handleLoad} {...props} />
  }
}
