// Utility method to move element in array
export const move = (from, to, ...a) =>
  from === to ? a : (a.splice(to, 0, ...a.splice(from, 1)), a)

export function uniqBy(a, key) {
  const seen = new Set()
  return a.filter((item) => {
    const k = key(item)
    return seen.has(k) ? false : seen.add(k)
  })
}

// Extract value of array that changes compare to previous array.
export function arrayDifferent(a, b, condition) {
  return a.filter((a, i) => b.findIndex(condition(a)) !== i)
}
