export const styles = (theme) => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    margin: theme.spacing.unit,
    width: '100%',
  },
})
