import { Grid, Typography } from '@material-ui/core'
import React, { memo } from 'react'
import { OutlinedField } from '../Forms'
const TextField = memo(
  ({ classes, onChange, value, label, placeholder }: any) => {
    return (
      <Grid item container sm={8} md={6}>
        <Grid item xs={12} sm={6} container alignItems="center">
          <Typography>{label}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <OutlinedField
            onChange={onChange}
            className={classes.textField}
            value={value}
            placeholder={placeholder}
          />
        </Grid>
      </Grid>
    )
  },
)

export default TextField
