import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LinkIcon from '@material-ui/icons/Link'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withEpisodes } from '../../contexts/data-context'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'
import GameForm from '../../components/GameForm'
import { gameEditStyle as styles } from './styles'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const GameDetail = (props) => {
  const { classes, enqueueSnackbar, history } = props
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const [game, setGame]: any = useState({
    category: 'game',
    referenceSource: 'episodes',
  })

  const fetchData = async () => {
    setIsLoading(true)
    // The episode may be given in the url
    const game = await Helper.ApiRequest(
      `/activities/${id}?$select[]=category&$select[]=content&$select[]=description&$select[]=isFree&$select[]=reference&$select[]=referenceSource&$select[]=state&$select[]=thumbnail&$select[]=title&$select[]=_id&$select[]=handbook`,
    )
    setGame({ ...game })
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveGame = async () => {
    const { reference, isFree }: any = game

    if (Object.keys(reference).length > 0 && isFree) {
      if (!reference.isFree) {
        enqueueSnackbar('You cannot link free game with paid episode.', {
          variant: 'error',
        })
        return
      }
    }

    await doAndSnack(getSaveRequest(), enqueueSnackbar, {
      success: 'Game succesfully saved',
      fail: 'Failed to save game',
      onSuccess,
    })
  }

  const onSuccess = () => {
    const { _id } = game
    history.replace(`/games/${_id}`)
    fetchData()
  }

  const getSaveRequest = async () => {
    const { _id } = game
    const path = `/activities/${_id}`
    const method = 'PUT'
    const body = JSON.stringify(game)
    return await Helper.ApiRequest(path, { method, body })
  }

  const handleChange = (game) => {
    setGame({ ...game })
  }

  return (
    <>
      <FormTitle />
      <Paper className={classes.paper}>
        {!isLoading && (
          // @ts-expect-error legacy
          <GameForm onChange={handleChange} game={game} onSave={saveGame} />
        )}
      </Paper>
    </>
  )
}

GameDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(
  // @ts-expect-error legacy
  withEpisodes(withRouter(withStyles(styles)(GameDetail))),
)

function FormTitle({
  reference = { title: null, slug: null },
  referenceSource = null,
}) {
  const { title, slug } = reference || {}
  let refName, link
  switch (referenceSource) {
    case 'episodes':
      refName = 'Episode'
      link = `/episodes/${slug}/games`
      break
    case 'chapters':
      refName = 'Chapter'
      link = `/chapters/${slug}`
      break
    default:
      return null
  }
  return (
    <Typography component="h2" variant="h4" align="center">
      {refName} Title : {title}
      <Link to={link}>
        <LinkIcon />
      </Link>
    </Typography>
  )
}
