import React, { useEffect, useState } from 'react'
import { ICompetitionNew, ICompetitionNewAdd, IPath } from './type'
import CompetitionForm from '../../components/CompetitionForm'
import { Paper, Typography, withStyles } from '@material-ui/core'
import { doAndSnack, snackProgressBar } from '../../utils/snackbar'
import { withRouter } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import Helper from '../../utils/Helper'
import { withPaths } from '../../contexts/data-context'
import { OVERLAP_DATE_MESSAGE } from '../../constants/ErrorMessages'

const styles: any = (theme) => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
  },
})

function basicValidateCompetition(
  competition: ICompetitionNewAdd,
  enqueueSnackbar,
) {
  const validations = [
    { check: () => !competition?.title, message: 'Title is required' },
    {
      check: () => competition?.selectedPaths?.length === 0,
      message: 'Level is required',
    },
    { check: () => !competition?.startDate, message: 'Start Date is required' },
    { check: () => !competition?.endDate, message: 'End Date is required' },
    { check: () => !competition?.slug, message: 'Slug is required' },
  ]

  for (const validation of validations) {
    if (validation.check()) {
      snackProgressBar(
        enqueueSnackbar,
        { success: '', fail: validation.message },
        false,
      )
      return false
    }
  }

  return true
}

const CompetitionAdd = (props) => {
  const { classes, enqueueSnackbar, history, paths } = props

  const [competition, setCompetition] = useState<ICompetitionNewAdd>({
    title: '',
    selectedPaths: [...paths],
    startDate: '',
    endDate: '',
    state: 'draft',
    slug: '',
  })

  const [allCompetitions, setAllCompetitions] = useState<ICompetitionNew[]>()
  const [reservedDates, setReservedDates] = useState([])

  useEffect(() => {
    const calculateReservedDates = () => {
      const selectedPathsSet = new Set(
        competition?.selectedPaths.map((path) => path._id),
      )

      const filteredCompetition = allCompetitions?.filter((oneCompetition) => {
        const isPublished = oneCompetition.state === 'published'
        const hasOverlappingPaths = oneCompetition.selectedPaths.some((path) =>
          selectedPathsSet.has(path._id),
        )

        return isPublished && hasOverlappingPaths
      })

      const newReservedDates = filteredCompetition?.map((competition) => {
        const startDate = new Date(competition.startDate)
        const endDate = new Date(competition.endDate)

        // Set start date to midnight (00:00)
        startDate.setHours(0, 0, 0, 0)
        // Set end date to the end of the day (23:59:59:999)
        endDate.setHours(23, 59, 59, 999)
        return {
          startDate,
          endDate,
        }
      })

      return newReservedDates
    }

    setReservedDates(calculateReservedDates())
  }, [competition?.selectedPaths, allCompetitions, competition?._id])

  const handleChange = (competition) => {
    setCompetition({
      ...competition,
    })
  }

  const fetchAllCompetitions = async () => {
    try {
      const result = await Helper.ApiRequest('/competitionsNew')

      setAllCompetitions(result)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchAllCompetitions()
  }, [])

  const saveCompetition = () => {
    try {
      const isCompetitionValidated = basicValidateCompetition(
        competition,
        enqueueSnackbar,
      )

      if (!isCompetitionValidated) return

      // check if it is a valid date & if overlapped
      if (competition?.startDate && competition?.endDate) {
        const validDate = Helper.validateDateRange(
          competition.startDate,
          competition.endDate,
          enqueueSnackbar,
        )

        let dateOverlapped = false

        const startDate = new Date(competition.startDate)
        const endDate = new Date(competition.endDate)

        // Filter competitions that do share any selectedPath with the new competition
        const allActiveOverlappedPathsCompetition = allCompetitions.filter(
          (existingCompetition) => {
            // Check if none of the selected paths of existingCompetition match those of new competition
            return existingCompetition.selectedPaths.some(
              (existingPath) =>
                competition.selectedPaths.some(
                  (newPath: IPath) => newPath._id === existingPath._id,
                ) && existingCompetition.state === 'published',
            )
          },
        )

        for (let i = 0; i < allActiveOverlappedPathsCompetition?.length; i++) {
          const oneCompetition = allActiveOverlappedPathsCompetition?.[i]

          const existingStartDate = new Date(oneCompetition.startDate)
          const existingEndDate = new Date(oneCompetition.endDate)

          if (startDate <= existingEndDate && existingStartDate <= endDate) {
            dateOverlapped = true
            snackProgressBar(
              enqueueSnackbar,
              {
                success: '',
                fail: OVERLAP_DATE_MESSAGE,
              },
              false,
            )
            break
          }
        }

        if (!validDate || dateOverlapped) return
      }

      const compEndDate = new Date(competition.endDate)
      compEndDate.setUTCHours(23, 59, 59, 999)
      competition.endDate = compEndDate.toISOString()

      const promise = Helper.ApiRequest(`/competitionsNew`, {
        method: 'POST',
        body: JSON.stringify(competition),
      })

      const onSuccess = () => {
        history.push('/competitions')
      }

      doAndSnack(promise, enqueueSnackbar, {
        success: 'Competition succesfully added',
        fail: 'Failed to add competition',
        onSuccess,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Typography component="h1" variant="h4" align="center">
        Add a new competition
      </Typography>
      <Paper className={classes.paper}>
        <CompetitionForm
          // @ts-expect-error legacy
          competition={competition}
          onChange={handleChange}
          onSave={saveCompetition}
          reservedDates={reservedDates}
        />
      </Paper>
    </>
  )
}

export default withPaths(
  withSnackbar(withRouter(withStyles(styles)(CompetitionAdd))),
)
