import React, { useEffect, useState } from 'react'
import { Grid, Paper, Typography, withStyles, Switch } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { FeaturesStyle as styles } from './style'
import Helper from '../../utils/Helper'

const Features = (props: any) => {
  const { classes } = props
  const [features, setFeatures]: any = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const data = await Helper.ApiRequest('/features')
    setFeatures(data)
  }

  const updateData = async (feature) => {
    const body = JSON.stringify(feature)
    const path = `/features/${feature._id}`
    const method = 'PUT'
    await Helper.ApiRequest(path, { method, body })
    fetchData()
  }

  const handleSwitch = (e, feature) => {
    e.preventDefault()
    feature.status = e.target.checked
    updateData(feature)
  }

  return (
    <>
      <Typography component="h1" variant="h5" align="center">
        Manage Features Enabled/Disabled
      </Typography>
      <Paper className={classes.paper}>
        <Grid
          className={classes.container}
          container
          spacing={8}
          direction="column"
          alignItems="center"
        >
          <div className={classes.container}>
            {features.map((feature) => (
              <Grid item container alignItems="center" justify="space-around">
                <Grid item xs={5}>
                  {feature.title}
                </Grid>
                <Grid item>
                  <Switch
                    checked={feature.status}
                    onChange={(e) => {
                      handleSwitch(e, feature)
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </div>
        </Grid>
      </Paper>
    </>
  )
}

export default withRouter(withStyles(styles)(Features))
